#App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  text-align: center;
  overflow: hidden;
  width: 100%;
  height: 100%;   
  background-color: #FDE7E7;  
}

.Title{
   font-family: 'Roboto', sans-serif;
   color:black;
   margin-top: 30px;
   position: relative;
   font-size: 80px;
   text-decoration: underline;
}

.Title2{
  font-family: 'Roboto', sans-serif;
  color:black;
  margin-top: 30px;
  position: relative;
  font-size: 50px;

}

#Gallery2{
  width: 100%;
  margin-top:50px;
}

#Container{
  position: relative;
  top:100px;
  width: 100%;
  height: 100%;  
}


.Logo{
  height: 100px;
   
}

 .wide{
   position: relative;
   width: 100% !important;
 }

 h1 {
  font-weight: 80 !important;
  font-style: normal !important;
  color: lightgrey
}

h2 {
  font-weight: 100 !important;
  font-style: normal !important;
  font-size: 40px;
  color: lightgrey
}

.MuiDialog-paperWidthSm-346{
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
}

.logoImage{
  background-size: 150% 90% !important;
}


blockquote{
  font-size: 30px;
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: .5em 10px;
  padding: 0 10px;
  quotes: "\201C""\201D""\2018""\2019";
  padding: 10px 20px;
  line-height: 1.4;
}

blockquote:before {
  content: open-quote;
  display: inline;
  height: 0;
  line-height: 0;
  left: -10px;
  position: relative;
  top: 30px;
  color: #ccc;
  font-size: 3em;
}

p{
  margin: 0;
}

footer{
  margin:0;
  text-align: right;
  font-size: 1em;
  font-style: italic;
}